let locationParksPromise = null;
let locationParksCache = [];

// initial state
const state = () => ({
  locationParks: []
})

// getters
const getters = {
  /**
   * Parks with available holidays nested by location
   */
  holidayLocationParks: (state) => (
    state.locationParks
      .map((location) => ({
        ...location,
        // Remove parks which don't have holidays
        parks: location.parks.filter(({types}) => types.length),
      }))
      // Remove locations which don't have parks with holidays
      .filter(({parks}) => parks.length)
  ),
  /**
   * Parks with available caravans nested by location
   */
  caravanLocationParks: (state) => (
    state.locationParks
      .map((location) => ({
        ...location,
        // Remove parks which don't have caravans
        parks: location.parks.filter(({caravanTypes}) => caravanTypes.length),
      }))
      // Remove locations which don't have parks with caravans
      .filter(({parks}) => parks.length)
  ),
  /**
   * A flattened array of all parks and an entry for the location, including those which don't
   * currently have any holidays available
   */
  flattenedLocationParks: ({locationParks}) => locationParks.flatMap(({parks, title, slug}) => [
    // Add an entry for all parks in the location
    {
      title: `All ${title} parks`,
      code: `all_${slug}`,
      // Handles rather than type objects. Using Set to remove duplicates
      types: [...new Set(parks.flatMap((park) => park.types))],
    },
    ...parks,
  ]),
}

// actions
const actions = {
  async fetchHolidayLocationParks({state, commit}) {
    // Do nothing if the local state already got the necessary data
    if (state.locationParks.length) return;

    // Otherwise let's wait for the global promise to be resolved
    // once it's resolved we should have a cached data that we can use for other stores
    if (locationParksPromise) {
      return locationParksPromise.then(() => {
        commit('setLocationParks', locationParksCache);
      });
    }

    // Fetch the data and cache it globally to avoid repetitive calls
    locationParksPromise = fetch('/actions/ep/booking/get-location-parks')
      .then(response => response.json())
      .then(locationParks => {
        locationParksCache = locationParks;

        commit('setLocationParks', locationParks);
      });
  }
}

// mutations
const mutations = {
  setLocationParks(state, locationParks) {
    state.locationParks = locationParks;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
