// This module provides a means to listen to media query change events. It replicates the
// same breakpoint logic as the corresponding SCSS mixin; using rems instead of pixels.

export const breakpoints = {
  searchBar: 828,
  mobileMenu: 985,
};

/**
 * @param {number} px
 * @returns {number}
 */
function pxToRem(px) {
  const rootFontSize = parseInt(getComputedStyle(document.documentElement).fontSize, 10);
  return px / rootFontSize;
}

/**
 * @param {number} breakpoint
 * @param {boolean} useMin Whether to use a min-width query rather the default max-width
 */
export default function getMedia(breakpoint, useMin = false) {
  const query = `only screen and (${useMin
    ? `min-width: ${pxToRem(breakpoint)}`
    : `max-width: ${pxToRem(breakpoint - 1)}`
  }rem)`;
  return window.matchMedia(query);
}
